.file_reader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.97);
  // display: flex;
  // display: -webkit-flex;
  // flex-direction: column;
  // align-items: center;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 20px;
  z-index: 1000;

  .file {
    // max-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .pdfPage {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    }

    .pdfPage > canvas {
      max-width: 100%;
    }
  }

  .page_row {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    z-index: 2000;

    .page_button {
      margin: 0 40px;
      font-weight: bold;
      cursor: pointer;
      padding: 10px 25px;
      border: 2px solid #97ca08;
      border-radius: 20px;

      &:hover {
        background: #97ca08;
      }

      @media (max-width: 443px) {
        margin: 0 10px;
      }
    }

    .page_number {
      font-weight: bold;
    }
  }

  .close_button {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 3000;
    background: url("../../assets/images/close.png") no-repeat center;
    background-size: contain;
    cursor: pointer;
  }
}
