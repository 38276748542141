@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat/Montserrat-Regular.ttf);
}

body {
  font-family: Montserrat;
  min-height: 100%;
}

.App {
  text-align: center;
}

.container {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
}

.carousel_title {
  margin: 60px 0 30px 0;
  font-weight: bold;
  font-size: 23px;
  color: #2e2e2e;
}

.carousel_button {
  margin: 30px 0;
  text-align: center;
}

.carousel_button a {
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.04em;
  text-decoration: none;
  color: #6b881b;
  display: inline-block;
  background: linear-gradient(180deg, #bced30 0%, #cffb53 50.52%, #bced30 100%);
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  overflow: hidden !important;
}

.categories_page {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  max-width: 90%;
  margin: 0 auto;
}

.categories_sidebar {
  width: 30%;
}

.categories_grid_holder {
  width: 65%;
}

.categories_grid_holder .carousel_holder .slider_item {
  /* height: 300px; */
}

.categories_grid_holder .container.carousel_holder {
  max-width: 100%;
}

.categories_grid_holder .container.carousel_holder .slick-track {
  margin-left: 0;
}

@media (max-width: 768px) {
  .categories_page {
    justify-content: center;
  }

  .categories_sidebar {
    display: none;
  }
}

@media (max-width: 950px) and (min-width: 481px) {
  .categories_grid_holder {
    width: 100%;
  }
}
