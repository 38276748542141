@import '../../sass/variable.scss';
//@import '/variable.scss';

.navbar {
    
    a {
        text-decoration: none;
        color: $navbarTexColor;
        font-weight: bold;
        font-size: 15px;
        letter-spacing: 0.04em;
        margin-left: 30px;
        text-transform: uppercase;
    }
    
}

@media (max-width: 860px) {
    .navbar a {
       margin-left: 10px; 
    }
}