.search_box {
  margin-left: 70px;
  position: relative;

  &.mobile_search {
    width: 100%;
  }

  input {
    background: #ffffff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.09);
    border-radius: 100px;
    width: 255px;
    height: 35px;
    padding: 0 55px 0 20px;
    font-size: 15px;
    color: #a4a4a4;
    &:focus {
      outline: none;
    }
  }
  .search_button {
    position: absolute;
    right: 0;
    top: 0;
    width: 49px;
    height: 31px;
    background-image: url("../../assets/images/search_icon.png");
    background-repeat: no-repeat;
    background-position: right center;
    margin-top: 2px;
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  .search_box {
    margin-left: 30px;
    position: relative;
  }

  .search_box input {
    width: 200px;
  }
}

@media (max-width: 885px) {
  .search_box {
    width: 250px;
    margin: 0 auto;

    input {
      width: 250px;
    }
  }
}
