.list_item_wrap {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  text-decoration: none;

  .list_item {
    background: #efefef;
    border-radius: 3px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;

    .list_item_img {
      width: 45%;

      img {
        width: 100%;
      }
    }

    .list_item_content {
      width: 52%;
      text-align: left;
      padding-top: 7px;
      position: relative;
      padding-right: 7px;
      box-sizing: border-box;

      .title {
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.04em;
        color: #555555;
        text-decoration: none;
      }

      .date {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.04em;
        color: #555555;
        margin-top: 10px;
        margin-bottom: 20px;
        text-decoration: none;
      }

      .description {
        font-size: 14px;
        letter-spacing: 0.04em;
        color: #555555;
        text-decoration: none;
      }

      .price {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.04em;
        color: #82a913;
        text-align: right;
        position: absolute;
        right: 10px;
        bottom: 20px;

        @media (max-width: 1265px) {
          bottom: 6px !important;
        }
      }
    }
  }
}
