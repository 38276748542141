.carousel_holder {
  .slick-slider {
    //height: 350px;
    //height: 36vh;
  }

  .slider_item {
    //height: 36vh;
    padding: 0 20px;
    box-sizing: border-box;
    cursor: pointer;

    &:focus {
      outline: none !important;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .slider_item_bg {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;

      &:focus {
        outline: none !important;
      }
    }
  }

  .slick-arrow {
    z-index: 900;
    width: 32px;
    height: 56px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &::before {
      display: none;
    }

    &.slick-prev {
      left: -50px;
      background: url("../../assets/images/carousel_arrow_left.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    &.slick-next {
      right: -50px;
      background: url("../../assets/images/carousel_arrow_right.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

@media (max-width: 950px) and (min-width: 481px) {
  .carousel_holder .slick-slider .slider_item {
    height: 200px;
  }
}

@media (min-width: 950px) {
  .carousel_holder .slick-slider .slider_item {
    height: 270px;
  }
}
