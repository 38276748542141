.auth_form_holder {
  padding: 25vh 25vw;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  background: rgba(255, 255, 255, 0.95);

  .auth_form_bg {
    background: linear-gradient(45deg, #6ba3df, #8d55c1);
    padding: 2px;
    border-radius: 5px;
    position: relative;

    .registration_sucess {
      width: 30vw;
      height: 40vh;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      svg {
        width: 70px;
        height: 70px;
        fill: #00ff00;
      }
      .success_text {
        font-size: 16px;
      }
      button {
        margin-top: 25px;
        height: 40px;
        line-height: 40px;
        text-transform: uppercase;
        background: #888888;
        border-radius: 3px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.13em;
        color: #ffffff;
        border: none;
        padding: 0 60px;
        cursor: pointer;

        &:hover {
          background: #8568c8;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .close_button {
      width: 22px;
      height: 22px;
      position: absolute;
      top: 15px;
      right: 15px;
      background: url("../../assets/images/close.png") no-repeat center;
      background-size: contain;
      cursor: pointer;
    }

    .auth_form_cup {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      width: 91px;
      height: 89px;
      background: url("../../assets/images/authFormCup.png") no-repeat center;
      background-size: contain;
    }

    .auth_form {
      width: 40vw;
      background: #fff;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 90px 20px 70px 20px;
      box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      box-sizing: border-box;

      .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.13em;
        color: #000000;
        text-transform: uppercase;
      }

      input {
        border: 1px solid #dddddd;
        box-sizing: border-box;
        border-radius: 3px;
        width: 45%;
        height: 35px;
        line-height: 35px;
        padding: 0 10px;
        font-size: 14px;
        margin-top: 20px;

        &::placeholder {
          color: #aaa;
        }

        &:focus {
          outline: none;
        }
      }

      .auth_error_message {
        width: 45%;
        text-align: left;
        color: #f00;
        margin-top: 10px;
        font-size: 12px;

        &.terms {
          text-align: center;
        }
      }

      button {
        margin-top: 25px;
        height: 40px;
        line-height: 40px;
        text-transform: uppercase;
        background: #888888;
        border-radius: 3px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.13em;
        color: #ffffff;
        border: none;
        padding: 0 60px;
        cursor: pointer;

        &:hover {
          background: #8568c8;
        }

        &:focus {
          outline: none;
        }
      }

      .register_row {
        margin-top: 30px;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.04em;
        color: #555555;
        padding-top: 30px;
        width: 45%;
        border-top: 1px solid #eee;

        span {
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 0.04em;
          text-decoration: underline;
          color: #8568c8;
          cursor: pointer;
        }
      }

      .forgot_password {
        font-size: 13px;
        letter-spacing: 0.04em;
        color: #555555;
        margin-top: 20px;

        span {
          font-weight: 600;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .check_box_row {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

        input {
          width: 16px;
          height: 16px;
          margin: 0;
        }

        .checkbox_label {
          display: inline-block;
          font-size: 15px;
          line-height: 17px;
          letter-spacing: 0.04em;
          color: #777777;
          margin-left: 5px;
        }
      }
    }
  }
}

@media (max-width: 885px) {
  .auth_form_holder {
    padding: 0 !important;

    .auth_form_bg {
      margin-top: 70px;
      width: 80%;
      .registration_sucess {
        width: 100% !important;
      }
      .auth_form {
        width: 100% !important;

        input {
          width: 90% !important;

          &[type="checkbox"] {
            width: 20% !important;
          }
        }

        .auth_error_message {
          width: 90% !important;
        }

        .register_row {
          width: 90%;
        }
      }
    }
  }
}
