.editions_mobile {
  margin-top: 12px;
  display: none;

  .editions_mobile_title {
    background: #e1e1e1;
    height: 45px;
    line-height: 45px;
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    color: #7d7d7d;
    letter-spacing: 0.09em;
    border-radius: 3px 3px 0 0;
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 50px;
    align-items: center;
    cursor: pointer;

    img {
      width: 25px;
      height: 15px;
      transition: 0.2s;

      &.rotated-icon {
        transform: rotate(-180deg);
      }
    }
  }

  .editions_mobile_list {
    padding: 0 15px;
    border: 1px solid #ddd;
    display: none;
    transition: 0.2s;

    &.list_displayed {
      display: block;
      transition: 0.5s all;
      height: 300px;
      overflow-y: scroll;
    }

    .editions_mobile_item {
      font-weight: bold;
      font-size: 17px;
      letter-spacing: 0.04em;
      color: #555555;
      text-align: center;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #ddd;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

@media (max-width: 850px) {
  .editions_mobile {
    display: block;
  }
}

@media (max-width: 373px) {
  .editions_mobile_title {
    padding: 0 8px 0 20px !important;
    font-size: 14px !important;
  }
}
