.account_bar {
  height: 100%;
  padding: 0 20px 0 130px;
  display: flex;
  justify-content: center;
  align-items: center;

  .loged_account {
    height: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    padding-right: 30px;

    .icon {
      width: 30px;
      height: 30px;
      margin: 0 10px;
      position: relative;
      cursor: pointer;

      path {
        fill: red;
      }

      .notification {
        position: absolute;
        width: 14px;
        height: 14px;
        background: #ff4d4d;
        top: -7px;
        left: -7px;
        border-radius: 50%;
        color: #fff;
        font-size: 11px;
        line-height: 14px;
      }
    }
  }

  .login_button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 35px;
    background: #ffffff;
    border: 3px solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 100px;
    cursor: pointer;

    .login_label {
      text-transform: uppercase;
      margin-right: 15px;
      color: #525252;
      font-size: 13px;
      font-weight: 600;
    }
  }
}

@media (max-width: 860px) {
  .account_bar {
    padding: 0 30px 0 100px;
  }
}

@media (max-width: 768px) {
  .account_bar {
    padding: 0 10px 0 70px;
  }
  .account_bar .loged {
    width: 100%;
  }
}
