.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  background: url("../../assets/images/accountBarBg.svg") no-repeat right center;
  background-color: #fff !important;

  &.logedIn {
    background: url("../../assets/images/accountBarBgLoged.svg") no-repeat right
      center;
  }

  .mobile_nav {
    display: none;
  }

  .header_holder {
    height: 90px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    max-width: 100%;

    .header_logo {
      display: flex;
      display: -webkit-flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 10%;
      width: 35%;

      .logo {
        cursor: pointer;
      }
    }

    .nav_account_box {
      width: 55%;
      height: 100%;
      display: flex;
      display: -webkit-flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.nav-links {
  list-style: none;
  display: none;
  width: 100%;
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  z-index: 1001;
  background-color: #fff;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.15);
  padding-top: 20px;

  &.active-links {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .search_box_mobile {
      width: 50%;
    }
  }

  .listItem {
    position: relative;
    overflow: hidden;
    width: 150px;
    padding: 10px 0;
    margin: 0 auto;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    &.search {
      width: 100% !important;
      display: block;
    }

    a {
      color: #000;
    }

    .imgWrapper {
      width: 40px;
      margin-right: 15px;
      position: relative;

      .notification_mobile {
        position: absolute;
        width: 14px;
        height: 14px;
        background: #ff4d4d;
        top: -7px;
        left: -1px;
        border-radius: 50%;
        color: #fff;
        font-size: 11px;
        line-height: 14px;
      }
    }
  }
}

@media (max-width: 1240px) {
  .header .header_holder .header_logo {
    margin-left: 3%;
  }
}

@media (max-width: 1150px) {
  .header .header_holder {
    .header_logo img {
      max-width: 120px;
    }
    .search_box_container {
      display: none;
    }

    .nav_account_box {
      display: none;
    }
  }

  .mobile_nav {
    display: block !important;
    position: absolute !important;
    height: 22px;
    width: 38px;
    right: 25px;
    top: 38px;
    cursor: pointer;

    .line {
      position: absolute;
      right: 0;
      top: 0;
      width: 38px;
      height: 3px;
      background: #fff;
      transition: 0.5s;
      z-index: 20;

      &.active-line {
        top: 5px !important;
        transform: rotate(135deg);
      }

      &.active-line:last-child {
        top: 5px !important;
        transform: rotate(-135deg);
      }
    }

    .line:nth-child(2) {
      top: 15px;
    }
  }
}

@media (max-width: 450px) {
  .header {
    background: url("../../assets/images/accountBarMobile.png") no-repeat right
      center;
    background-position-x: 15ch;

    &.logedIn {
      background: url("../../assets/images/accountBarLogedMobile.png") no-repeat
        right center;
    }
  }
}
