.category_header {
  margin: 20px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;

  .category_title {
    font-weight: bold;
    font-size: 17px;
    letter-spacing: 0.04em;
    color: #555555;
  }

  // .show_all {
  //     font-weight: bold;
  //     font-size: 16px;
  //     letter-spacing: 0.04em;
  //     color: #555555;
  //     cursor: pointer;
  // }
}

.categories_grid {
  .category_row {
    padding-bottom: 10px;
  }

  .show_all {
    text-align: center;
    margin-top: 30px;
    .show_all_btn {
      display: inline-block;
      font-weight: bold;
      font-size: 14px;
      height: 43px;
      line-height: 43px;
      letter-spacing: 0.04em;
      color: #ffffff;
      padding: 0 55px;
      background: #949494;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      cursor: pointer;
    }
  }
}
