.categories_sidebar_holder {
  padding-top: 20px;

  .categories_list {
    padding: 0 15px;
    border: 1px solid #ddd;
  }

  .categories_sidebar_title {
    background: #e1e1e1;
    height: 45px;
    line-height: 45px;
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    color: #7d7d7d;
    letter-spacing: 0.09em;
    border-radius: 3px 3px 0 0;

    @media (max-width: 950px) {
      height: 70px;
      line-height: 35px;
    }
  }

  .category_item {
    font-weight: bold;
    font-size: 17px;
    letter-spacing: 0.04em;
    color: #555555;
    text-align: left;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    overflow: hidden;

    &:last-child {
      border-bottom: none;
    }
  }
}

@media (max-width: 850px) {
  .categories_sidebar_holder {
    display: none;
  }
}
