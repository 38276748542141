.footer {
  width: 100%;
  background: url("../../assets/images/footer_bg.png") no-repeat center;
  background-size: cover;
  position: relative;
  margin-top: 110px;

  .footer_overlay {
    padding: 100px 0 90px 0;
    background: linear-gradient(
      180deg,
      #ffffff 9.52%,
      rgba(248, 248, 248, 0) 100%
    );
  }

  .container {
    display: flex;
  }

  .footer_col {
    width: 25%;
    display: inline-block;
    vertical-align: top;
    color: #555555;
    text-align: left;

    .footer_col_title {
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 15px;
    }

    .footer_col_item {
      font-size: 16px;
      margin-bottom: 5px;

      a {
        text-decoration: none;
        color: #555555;
      }
    }

    &.links .footer_col_item {
      margin-bottom: 9px;
    }

    &.logo {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;

      .cards_row {
        display: flex;
        display: -webkit-flex;
        justify-content: flex-end;
        align-items: center;

        img {
          margin-left: 20px;
        }
      }
    }
  }

  .footer_bottom {
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: 0;
    left: 0;
    background: url("../../assets/images/footer_bottom_bg.png") no-repeat center;
    background-size: cover;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0.4px;
  }
}

@media (max-width: 1024px) {
  .footer .footer_overlay .container {
    display: block;
    // margin: 0;

    .footer_col {
      width: 33%;
    }

    .footer_col.logo {
      width: 100%;
      overflow: hidden;

      .footer_logo {
        margin: 0 auto;
      }

      .cards_row {
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 732px) {
  .footer {
    margin-top: 20px !important;

    .footer_overlay {
      padding-top: 20px !important;
    }
  }

  .footer .footer_overlay .container {
    max-width: 100% !important;
  }

  .footer .footer_overlay .container .footer_col {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .footer_col_title {
      font-size: 14px;
    }

    .footer_col_item {
      font-size: 14px;
    }
  }

  .footer_col.logo {
    width: 100%;
    text-align: center;

    .footer_logo {
      margin: 0 auto !important;
    }

    .cards_row {
      align-items: center;
      justify-content: center !important;
    }
  }
}
