.category_page_holder {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: flex-start;
  .data_list_holder {
    width: 65%;

    .category_name {
      font-weight: bold;
      font-size: 17px;
      letter-spacing: 0.04em;
      color: #555555;
      padding: 30px 0 15px 0;
      margin-bottom: 15px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }

    .data_list {
      display: flex;
      display: -webkit-flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .data_item {
        width: 25%;
        margin-right: 1%;
        margin-bottom: 10px;
        cursor: pointer;

        img {
          max-width: 100%;
        }
      }
    }
  }

  .category_sidebar {
    width: 30%;
  }
}

@media (max-width: 850px) {
  .category_page_holder {
    display: block;
    margin: 0 auto;

    .data_list_holder {
      width: 100% !important;

      .data_list {
        .data_item {
          width: 40%;
        }
      }
    }
  }
}

@media (max-width: 570px) {
  .data_list {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .data_item {
      width: 60% !important;
    }
  }
}

@media (max-width: 1242px) and (min-width: 850px) {
  .data_item {
    width: 35% !important;
  }
}
