.loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  height: 100%;
  img {
    width: 200px;
  }
}

.fullPage {
  z-index: 1001;
  top: 0; 
  left:0;
}
