.Heart {
  width: 30px;
  height: 30px;
}
// Fill
.fill path {
  fill: #FF0000;
}

.fill path:hover {
  fill: #cecece;
}
// Empty
.empty path {
  fill: #cecece;
}

.empty path:hover {
  fill: #FF0000;
}
// Menu option
.menuOption path {
  fill: #FFFFFF;
}

.menuOption path:hover {
  fill: #FF0000;
}
// Dropdown menu option
.dropdownMenuOption path {
  fill: #676767;
}