.singlepage {
  // width: 100%;
  max-width: 80%;
  margin: 0 auto;

  .single_row {
    display: -webkit-flex;
    display: flex;
    padding: 50px 0;
    border-bottom: 1px solid #ddd;
    justify-content: space-between;
    // height: 37vh;

    .single_box {
      width: 70%;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      align-items: flex-start;
      .single_img {
        width: 25%;
        text-align: left;

        img {
          margin-left: -8px;
          width: 100%;
        }
      }

      .single_content_mobile {
        display: none;
      }

      .single_content {
        width: 72%;
        text-align: left;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        height: 100%;

        .single_title {
          font-weight: bold;
          font-size: 20px;
          letter-spacing: 0.04em;
          color: #555555;
          padding-bottom: 10px;
          border-bottom: 1px solid #ddd;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          p {
            margin: 0;
          }
          svg {
            width: 30px;
            height: 30px;
            stroke: red;
            cursor: pointer;

            &.active {
              fill: red;
            }
          }
        }

        .single_date {
          margin-top: 10px;
          font-weight: 600;
          font-size: 15px;
          letter-spacing: 0.04em;
          color: #555555;
        }

        .single_description {
          font-size: 15px;
          letter-spacing: 0.04em;
          color: #555555;
          margin-top: 25px;
          line-height: 1.5;
        }

        .single_buttons {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-top: 60px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .singlepage {
    max-width: 85%;
    .single_row {
      padding: 20px 0 10px 0 !important;

      .single_box {
        .single_content {
          width: 65% !important;

          .single_description {
            margin: 0 !important;
          }

          .single_buttons {
            flex-direction: column;
            align-items: flex-start !important;
            justify-content: space-evenly;
            margin-top: 10px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .single_box {
    .single_img {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 850px) {
  .singlepage {
    width: 100% !important;
    .single_box {
      width: 100% !important;

      .single_img {
        width: 40% !important;
      }

      .single_content {
        width: 50% !important;
        margin-left: 3%;

        .single_title {
          font-size: 16px !important;
        }

        .single_date {
          font-size: 14px !important;
        }

        .single_description {
          font-size: 14px !important;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .single_row {
    .single_box {
      flex-direction: column !important;
      align-items: center !important;
      .single_img {
        width: 70% !important;
        display: block;
        margin: 0 auto;
        text-align: center !important;
      }

      .single_content {
        display: none !important;
      }

      .single_content_mobile {
        display: block !important;
        width: 80%;

        .single_title_mobile {
          font-weight: bold;
          font-size: 20px;
          letter-spacing: 0.04em;
          color: #555555;
          padding-bottom: 10px;
          border-bottom: 1px solid #ddd;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }

        .single_date_mobile {
          margin-top: 10px;
          font-weight: 600;
          font-size: 15px;
          letter-spacing: 0.04em;
          color: #555555;
        }

        .single_description_mobile {
          font-size: 15px;
          letter-spacing: 0.04em;
          color: #555555;
          margin: 10px 0;
          line-height: 1.5;
        }
      }
    }
  }
}
