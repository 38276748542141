.sidebar_list_holder {
  width: 27%;
  background: #ffffff;
  box-shadow: 0px 5px 8px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  max-height: 400px;

  .sidebar_title {
    background: #e1e1e1;
    height: 45px;
    line-height: 45px;
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    color: #7d7d7d;
    letter-spacing: 0.09em;
    border-radius: 3px 3px 0 0;
    margin-bottom: 25px;
  }

  .list_wrap {
    padding: 0px 15px;
    height: calc(100% - 70px);
    box-sizing: border-box;
    overflow-y: scroll;

    .list_item_wrap {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #ddd;
      box-sizing: border-box;

      .list_item {
        background: #efefef;
        border-radius: 3px;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;

        .list_item_img {
          width: 45%;

          img {
            width: 100%;
          }
        }

        .list_item_content {
          width: 52%;
          text-align: left;
          padding-top: 7px;
          position: relative;
          padding-right: 7px;
          box-sizing: border-box;

          .title {
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0.04em;
            color: #555555;
          }

          .date {
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.04em;
            color: #555555;
            margin-top: 10px;
            margin-bottom: 20px;
          }

          .description {
            font-size: 14px;
            letter-spacing: 0.04em;
            color: #555555;
          }

          .price {
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.04em;
            color: #82a913;
            text-align: right;
            position: absolute;
            right: 10px;
            bottom: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .sidebar_list_holder {
    display: none;
  }
}

@media (min-width: 950px) and (max-width: 1151px) {
  .description {
    display: none !important;
  }
}
