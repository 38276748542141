.Favorites {
  .shared_toggle {
    display: flex;
    justify-content: center;
    align-items: center;

    .single_toggle {
      background: #dedede;
      width: 190px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #878787;
      font-weight: 600;
      letter-spacing: 1px;
      cursor: pointer;

      &.active {
        background-color: #343434;
        color: #fff;
      }
    }
  }
  .shared_grid {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;

    .shared_grid_item {
      margin: 0 20px;
      cursor: pointer;
      img {
        width: 180px;
        height: 250px;
      }
    }
  }
}
