.button_box {
  display: inline-block;
  width: 200px;
  margin-bottom: 10px;
  margin-right: 10px;

  &.cart {
    .price {
      color: #70940a;
    }

    .single_button {
      background: #97ca08;
    }
  }

  .price {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #555555;
    margin-bottom: 12px;
  }

  .single_button {
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #ffffff;
    height: 40px;
    line-height: 40px;
    background: #676767;
    border-radius: 100px;
    font-weight: bold;
    cursor: pointer;
  }
}

@media (max-width: 420px) {
  .button_box {
    width: 100%;

    .single_button {
      font-size: 12px;
      font-weight: 600;
    }
  }
}
