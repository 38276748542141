.promo_section {
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  height: 440px;
  background: url("../../assets/images/promo_bg.jpg");
  background-size: cover;
  max-width: 100%;

  .promo_item {
    margin: 0 80px;
    text-align: center;
    .promo_icon {
      width: 78px;
      display: inline-block;
      margin-bottom: 15px;
      padding-bottom: 5px;
      border-bottom: 5px solid;
      margin-bottom: 15px;
      padding-bottom: 10px;
      border-bottom: 4px solid rgba(85, 85, 85, 0.3);
    }

    .propmo_title {
      font-weight: bold;
      font-size: 17px;
      letter-spacing: 0.04em;
      color: #555555;
    }
  }
}

@media (max-width: 645px) {
  .promo_section {
    flex-direction: column;
  }

  .promo_item {
    padding-bottom: 30px;
  }
}

.tab_section {
  padding-bottom: 50px;
  .container.carousel_holder {
    max-width: 100%;
  }

  .tab_list {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    .tab_item {
      font-weight: bold;
      font-size: 15px;
      letter-spacing: 0.04em;
      color: #b4b4b4;
      margin: 0 30px;
      cursor: pointer;
      position: relative;

      &::before {
        content: "";
        width: 60%;
        height: 3px;
        background: #555555;
        display: inline-block;
        margin-bottom: 5px;
        opacity: 0;
      }

      &.active {
        color: #555555;
        &::before {
          opacity: 1;
        }
      }
    }
  }
}

.phone_section {
  max-width: 100%;
  height: 590px;
  background: url("../../assets/images/credit_bg.png") no-repeat center;
  background-size: cover;
  border-top: 10px solid #e0e0e0;
  border-bottom: 10px solid #e0e0e0;
  display: flex;

  .phone_box {
    width: 60%;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;

    .phone_bg {
      width: 100%;
      height: 85%;
      background: url("../../assets/images/mobile_bg_shadow.png") no-repeat left
        center;
      background-size: contain;
      display: flex;
      justify-content: center;
      padding-right: 20%;

      .phone_item {
        width: 50%;
        margin: 0 20px;
        .phone_img {
          height: 100%;
          display: inline-block;
          margin: -10px 35px 0 35px;
          img {
            height: 80%;
          }

          .phone_button {
            font-weight: bold;
            width: 180px;
            margin: 5px 15px 0 15px;
            padding: 25px 20px;
            position: relative;
            display: flex;
            display: -webkit-flex;
            justify-content: space-evenly;
            align-items: center;
            padding: 6px 25px;

            img {
              width: 25px;
            }
          }

          .phone_button.iphone_button {
            border-radius: 100px;
            background: linear-gradient(
              180deg,
              #c1c1c1 0%,
              #ffffff 48.96%,
              #c1c1c1 100%
            );
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
            // &::before {
            //   content: "";
            //   display: inline-block;
            //   height: 35px;
            //   width: 35px;
            //   background-image: url("../../assets/images/apple_icon.png");
            //   background-repeat: no-repeat;
            //   background-position: center;
            //   margin-right: 15px;
            // }
          }

          .phone_button.android_button {
            border-radius: 100px;
            background: linear-gradient(
              180deg,
              #676767 0%,
              #464646 48.96%,
              #111111 100%,
              #959595 100%
            );
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.18);
            color: #fff;
            // &::before {
            //   content: "";
            //   display: inline-block;
            //   height: 35px;
            //   width: 35px;
            //   background-image: url("../../assets/images/android_icon.png");
            //   background-repeat: no-repeat;
            //   background-position: center;
            //   margin-right: 15px;
            // }
          }
        }
      }
    }
  }

  .credit_buttons_box {
    width: 40%;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
      height: 70px;
      width: 325px;
      background: linear-gradient(
        360deg,
        #44be0b 0%,
        #66d532 50%,
        #43bb0a 100%
      );
      border: 2px solid #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      font-weight: bold;
      font-size: 18px;
      color: #fff;

      &.vaucher {
        background: linear-gradient(
          360deg,
          #df1e58 0%,
          #ff7092 50%,
          #ed1f75 100%
        );
      }

      .button_label {
        margin-left: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .home .carousel_section,
  .home .tab_section_wrapper {
    margin: 0 25px;
  }
  .tab_section.container.carousel_holder {
    max-width: 80%;
  }

  .phone_section .credit_buttons_box {
    flex-direction: column !important;
  }
}

@media (max-width: 1440px) {
  .phone_section .phone_box .phone_bg .phone_item .phone_img{
    height: 450px;
  }
}

@media (min-width: 1100px) and (max-width: 1304px) {
  .phone_section .phone_box {
    width: 75% !important;
  }
}

@media (max-width: 1304px) {
  .phone_section {
    flex-direction: column;
  }

  .phone_section .credit_buttons_box {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .phone_section .phone_box {
    width: 100%;
  }

  .phone_section .phone_box .phone_bg {
    width: 100%;
    padding-right: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .phone_section .phone_box .phone_bg .phone_item {
    width: 40%;
  }

  .phone_section .phone_box .phone_bg .phone_item, .phone_img {
    height: 470px;
  }

  .phone_section .phone_box .phone_bg .phone_item,
  .phone_section .phone_box .phone_bg .phone_item .phone_img {
    margin: 0;
    // width: 50%;
  }

  .phone_section .phone_box .phone_bg .phone_item .phone_button {
    margin: 0;
    width: 60%;
  }
}

@media (max-width: 900px) {
  .phone_section {
    height: 780px;
  }

  .phone_section .phone_box {
    height: 510px;
  }

  .phone_section .phone_box .phone_bg {
    background: none;
  }

  .phone_section .phone_box .phone_bg .phone_item {
    width: 50%;
    height: 400px;
  }

  .phone_section .phone_box .phone_bg .phone_item .phone_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
  }

  .phone_button span {
    display: none;
  }

  .phone_button {
    width: 75px !important;
  }

  .phone_button img {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 472px) {
  .phone_section {
    height: 650px;
  }

  .phone_section .phone_box {
    height: 400px;
  }

  .phone_section .phone_box .phone_bg .phone_item {
    height: 300px;
  }

  .phone_section .phone_box .phone_bg .phone_item .phone_img {
    height: 100%;
  }

  .phone_button {
    margin: 5px 0 !important;
  }

  .phone_section .credit_buttons_box .button {
    height: 57px;
    width: 270px;
    font-size: 16px;
  }
}
