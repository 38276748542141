.slider_holder {
  position: relative;

  .slick-slider {
    height: 60vh;
  }

  .slider_item {
    width: 100%;
    height: 60vh;

    &:focus {
      outline: none !important;
    }

    .slider_item_bg {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      &:focus {
        outline: none !important;
      }
    }
  }

  .slick-arrow {
    z-index: 900;
    width: 32px;
    height: 56px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &::before {
      display: none;
    }

    &.slick-prev {
      left: 30px;
      background: url("../../assets/images/arrow_left.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    &.slick-next {
      right: 30px;
      background: url("../../assets/images/arrow_right.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  ul.slick-dots {
    bottom: 20px;
    text-align: left;
    padding-left: 70px;
  }

  .slick-dots li button:before {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.49);
  }

  .slick-dots li.slick-active button:before {
    color: #fff;
  }

  .slider_app_buttons_holder {
    position: absolute;
    right: 0;
    bottom: -1px;
    width: 65%;
    height: 25%;
    background: url("../../assets/images/slider_buttons_holder_bg.png")
      no-repeat right bottom;
    background-size: contain;
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 3%;

    @media (max-height: 720px) {
      padding-right: 8px;
    }

    @media (max-width: 1175px) {
      background: none;
      position: relative;
      width: 100%;
      align-self: center;
      justify-content: center;
      padding-right: 0;
    }

    .app_button {
      font-weight: bold;
      min-width: 20%;
      margin: 20px 15px 0 15px;
      padding: 20px;
      position: relative;
      display: -webkit-flex;
      justify-content: center;
      align-items: center;
      padding: 15px 25px;
      font-size: 16px;
      letter-spacing: 0.04em;
    }

    .app_button.apple {
      border-radius: 100px;
      background: linear-gradient(
        180deg,
        #c1c1c1 0%,
        #ffffff 48.96%,
        #c1c1c1 100%
      );
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
      &::before {
        content: "";
        display: inline-block;
        height: 35px;
        width: 35px;
        background-image: url("../../assets/images/apple_icon.png");
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 15px;
      }
    }

    .app_button.android {
      border-radius: 100px;
      background: linear-gradient(
        180deg,
        #676767 0%,
        #464646 48.96%,
        #111111 100%,
        #959595 100%
      );
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.18);
      color: #fff;
      &::before {
        content: "";
        display: inline-block;
        height: 35px;
        width: 35px;
        background-image: url("../../assets/images/android_icon.png");
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 15px;
      }
    }
  }

  ul.slick-dots {
    width: 50%;
  }
}

@media (max-width: 505px) {
  .slider_app_buttons_holder {
    display: flex;
    flex-direction: column;

    .app_button {
      width: 60%;
    }
  }
}
